import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import qs from "query-string"
import React, { FormEvent, useEffect, useState } from "react"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"
import { getFirebaseApp } from "../../components/auth/FirebaseContext"

// If user goes to login page and remembers email, but forgets password,
// the email gets populated when they follow the "forgot password" link.
function parseEmailFromWindowLocation() {
  const { email } = qs.parse(window.location.search)
  return email
}

function ForgotPasswordForm() {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    const email = parseEmailFromWindowLocation()
    if (email && typeof email !== "string") {
      throw new Error("Expected `email` in url query string to be a string")
    }
    setEmail(email ?? "")
  }, [])

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setIsEmailSent(false)
    setError("")

    try {
      setIsLoading(true)
      await getFirebaseApp().auth().sendPasswordResetEmail(email)
      setIsEmailSent(true)
    } catch (err) {
      setError(err.message)
    }

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        {isEmailSent && (
          <Alert status="success">
            <AlertIcon />
            Check your inbox for the reset link!
          </Alert>
        )}
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            required
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            size="lg"
          />
          <FormHelperText>
            Enter your account's email address to get a password reset link.
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          size="lg"
          isLoading={isLoading}
        >
          Send Reset Link
        </Button>
      </FormVStack>
    </form>
  )
}

export default function ForgotPassword() {
  return (
    <FormLayout title="🧠 Reset Password" showBack>
      <ForgotPasswordForm />
    </FormLayout>
  )
}
